import { AuthResponseInterface } from "@/models/api/AuthResponse.interface";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import api from "@/api/baseApi";
import { AuthRequestInterface } from "@/models/auth/AuthRequest.interface";
import { UserInterface } from "@/models/store/User.interface";
import { RegisterRequestInterface } from "@/models/auth/RegisterRequest.interface";
import { BaseResponseInterface } from "@/models/api/BaseResponse.interface";
import {
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders
} from "axios";

export async function login(
  req: AuthRequestInterface
): Promise<AuthResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    AuthResponseInterface | ErrorResponseInterface
  > = await api.post("/Account/Login", req);

  return res.data;
}

export async function register(
  req: RegisterRequestInterface
): Promise<AuthResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    AuthResponseInterface | ErrorResponseInterface
  > = await api.post("/Account/register", req);

  return res.data;
}

export async function updateToken(
  token: string
): Promise<AuthResponseInterface | ErrorResponseInterface> {
  const res: AxiosResponse<
    AuthResponseInterface | ErrorResponseInterface
  > = await api.post("/Account/Refresh", {}, {
    headers: {
      Authorization: "Bearer " + token
    } as RawAxiosRequestHeaders
  } as AxiosRequestConfig);
  return res.data;
}

export async function getUsers(): Promise<UserInterface[]> {
  return (await api.post("/User/list")).data;
}

export async function confirmUser(
  userId: string
): Promise<BaseResponseInterface | ErrorResponseInterface> {
  return (await api.post(`User/confirm?id=${userId}`)).data;
}
